// ** AWS
import { Amplify } from 'aws-amplify'
import { AwsConfig } from '../configs/aws'

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      region: AwsConfig.region,
      userPoolId: AwsConfig.userPoolId,
      userPoolWebClientId: AwsConfig.userPoolWebClientId,
      identityPoolId: AwsConfig.identityPoolId,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      redirectSignIn: `${process.env.REACT_APP_HOST}/redirect-signin`,
      redirectSignOut: `${process.env.REACT_APP_HOST}/signin`,
      responseType: 'code',
      scope: ['openid', 'email', 'profile']
    }
  })
}
