// ** React
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// ** Screens
import SignInScreen from './SignIn'
import CompleteNewPasswordScreen from './CompleteNewPassword'

const AuthModule = () => {
  return (
    <Routes>
      <Route path='signin' element={<SignInScreen />} />
      <Route path='complete-new-password' element={<CompleteNewPasswordScreen />} />
      <Route path='redirect-signin' element={<Navigate to='/' replace />} />
      <Route path='/*' element={<Navigate to='signin' replace />} />
    </Routes>
  )
}

export default AuthModule
