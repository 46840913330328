// ** React
import React, { lazy, Suspense, useEffect } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'

// ** Screens
import DashboardScreen from './Dashboard'
import NotFoundScreen from './NotFound'

// ** Third Party
import NProgress from 'nprogress'

// ** Hooks
import useAuth from '../../hooks/useAuth'

// ** Layouts
import RamptdevLayout from './../../layouts/ramptdev'

// ** Screens loaded normally
import ProfileSetup from './ProfileSetup'

// ** Lazy Screens
export const Members = lazy(() => import('./Members'))
export const MembersDetail = lazy(() => import('./MembersDetail'))
export const Profile = lazy(() => import('./Profile'))
export const PositionsAdd = lazy(() => import('./PositionsAdd'))
export const PositionsDetail = lazy(() => import('./PositionsDetail'))
export const PositionsEdit = lazy(() => import('./PositionsEdit'))
export const PositionsRemove = lazy(() => import('./PositionsRemove'))
export const Projects = lazy(() => import('./Projects'))
export const ProjectsAdd = lazy(() => import('./ProjectsAdd'))
export const ProjectsDetail = lazy(() => import('./ProjectsDetail'))
export const ProjectsEdit = lazy(() => import('./ProjectsEdit'))
export const ProjectsRemove = lazy(() => import('./ProjectsRemove'))
export const TimeOffs = lazy(() => import('./TimeOffs'))
export const TimeOffsAdd = lazy(() => import('./TimeOffsAdd'))
export const TimeOffsDetail = lazy(() => import('./TimeOffsDetail'))
export const TimeOffsAdminDetail = lazy(() => import('./TimeOffsAdminDetail'))
export const TimeOffsRemove = lazy(() => import('./TimeOffsRemove'))

const ApplicationModule = () => {
  const { user } = useAuth()

  // Guards user profile setup
  if (user && !user?.title) {
    return <ProfileSetup />
  }

  const routes = useRoutes([
    {
      element: (
        <RamptdevLayout>
          <Suspense fallback={<ApplicationLazyLoad />}>
            <Outlet />
          </Suspense>
        </RamptdevLayout>
      ),
      children: [
        { element: <DashboardScreen />, index: true },
        { path: '/members', element: <Members /> },
        { path: '/members/:id', element: <MembersDetail /> },
        { path: '/profile', element: <Profile /> },
        { path: '/positions/add', element: <PositionsAdd /> },
        { path: '/positions/:id', element: <PositionsDetail /> },
        { path: '/positions/:id/edit', element: <PositionsEdit /> },
        { path: '/positions/:id/remove', element: <PositionsRemove /> },
        { path: '/projects', element: <Projects /> },
        { path: '/projects/add', element: <ProjectsAdd /> },
        { path: '/projects/:id', element: <ProjectsDetail /> },
        { path: '/projects/:id/edit', element: <ProjectsEdit /> },
        { path: '/projects/:id/remove', element: <ProjectsRemove /> },
        { path: '/time-offs', element: <TimeOffs /> },
        { path: '/time-offs/add', element: <TimeOffsAdd /> },
        { path: '/time-offs/:id', element: <TimeOffsDetail /> },
        { path: '/time-offs/admin/:id', element: <TimeOffsAdminDetail /> },
        { path: '/time-offs/:id/remove', element: <TimeOffsRemove /> }
      ]
    },
    {
      path: '/redirect-signin',
      element: <Navigate to='/' replace />
    },
    {
      path: '/redirect-signin/',
      element: <Navigate to='/' replace />
    },
    {
      path: '*',
      element: <NotFoundScreen />
    }
  ])

  return routes
}

const ApplicationLazyLoad = () => {
  useEffect(() => {
    NProgress.start()

    return () => {
      NProgress.done()
    }
  }, [])

  return <></>
}

export default ApplicationModule
