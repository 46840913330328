// ** React
import React from 'react'

// ** Utils
import PropTypes from 'prop-types'

// ** MUI
import Box from '@mui/material/Box'

// ** Hooks
import { useResponsive } from '../../hooks/useResponsive'

// ** Configs
import { NAV, HEADER } from './config-layout'

interface MainProps {
  children: React.ReactNode
  sx?: object
}

const SPACING = 8

export default function Main({ children, sx, ...other }: MainProps) {
  const lgUp = useResponsive('up', 'lg')

  return (
    <Box
      component='main'
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        px: 3,
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.WIDTH}px)`
        }),
        ...sx
      }}
      {...other}
    >
      {children}
    </Box>
  )
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
}
