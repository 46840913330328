// ** React
import React, { useState, MouseEvent } from 'react'

// ** MUI
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { alpha, Theme } from '@mui/material'

// ** Hooks
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

export default function AccountPopover() {
  const [open, setOpen] = useState<null | HTMLElement>(null)
  const { user, signOut$ } = useAuth()
  const navigate = useNavigate()

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleClickSettings = () => {
    handleClose()
    navigate('/profile')
  }

  const handleLogout = () => {
    handleClose()
    signOut$()
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={(theme: Theme) => ({
          width: 40,
          height: 40,
          background: alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`
          })
        })}
      >
        <Avatar
          src={user?.picture}
          alt={user?.name}
          sx={(theme: Theme) => ({
            width: 36,
            height: 36,
            border: `solid 2px ${theme.palette.background.default}`
          })}
        >
          {user?.name.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 0,
              mt: 1,
              ml: 0.75,
              width: 200
            }
          }
        }}
      >
        <MenuItem key={'settings'} onClick={handleClickSettings}>
          Settings
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleLogout}
          sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  )
}
