// ** React
import React from 'react'

// ** Components
import Iconify from '../../components/iconify'

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: <Iconify width={22} icon='akar-icons:dashboard' />
  },
  {
    title: 'Profile',
    path: '/profile',
    icon: <Iconify width={22} icon='gg:profile' />
  },
  {
    title: 'members',
    path: '/members',
    icon: <Iconify width={22} icon='ri:team-line' />
  },
  {
    title: 'projects',
    path: '/projects',
    icon: <Iconify width={22} icon='octicon:project-roadmap-16' />
  },
  {
    title: 'Time Offs',
    path: '/time-offs',
    icon: <Iconify width={24} icon='material-symbols:bedtime-off-outline' />
  }
  // {
  //   title: 'Positions',
  //   path: '/positions',
  //   icon: <Iconify width={24} icon='eos-icons:workload' />
  // }
]

export default navConfig
