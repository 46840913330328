// ** React
import React, { useEffect, useState } from 'react'

// ** MUI
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import Skeleton from '@mui/material/Skeleton'
import Button from '@mui/material/Button'

// ** Types
import { LinkRampt } from '../../../types/link'
import { Position } from '../../../types/position'

// ** Hooks
import useAuth from '../../../hooks/useAuth'
import useApi from '../../../hooks/useApi'

// ** Third Party
import { Link, useNavigate } from 'react-router-dom'
import GoogleMapReact from 'google-map-react'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

// ** Components
import AdminDivider from '../../../components/admin-divider'
import Iconify from '../../../components/iconify'

// ** Utils
import toast from 'react-hot-toast'
import { fmtAxiosMessage } from '../../../services/api/utils'

const DashboardScreen = () => {
  const api = useApi()
  const { user, role } = useAuth()
  const navigate = useNavigate()

  const [loadingPositions, setLoadingPositions] = useState(true)
  const [positions, setPositions] = React.useState<Position[]>([])

  const links: LinkRampt[] = [
    {
      number: '01',
      name: 'Onboarding Documentation',
      url: 'https://www.rampt.dev/'
    },
    {
      number: '02',
      name: 'Monthly Hours Report',
      url: 'https://www.rampt.dev/'
    },
    {
      number: '03',
      name: 'Vacation Request',
      url: '/time-offs'
    },
    {
      number: '04',
      name: 'Onboarding Doc',
      url: 'https://www.rampt.dev/'
    },
    {
      number: '05',
      name: 'Holiday Calendar',
      url: 'https://www.rampt.dev/'
    }
  ]

  const forms: LinkRampt[] = [
    {
      name: 'T-Shirts',
      url: 'https://www.rampt.dev/',
      icon: 'twemoji:t-shirt'
    },
    {
      name: 'Birthdays',
      url: 'https://www.rampt.dev/',
      icon: 'twemoji:birthday-cake'
    }
  ]

  const fetchPositions = async () => {
    setLoadingPositions(true)

    try {
      const resp = await api.positions.list()

      setPositions(resp.rows!)
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
    }

    setLoadingPositions(false)
  }

  useEffect(() => {
    fetchPositions()
  }, [])

  const handleMapLoaded = async (map: any, maps: any) => {
    const members = await api.users.list()

    const infoWindow = new maps.InfoWindow({
      content: '',
      disableAutoPan: true
    })

    const markers = members.rows!.map(member => {
      const marker = new maps.Marker({
        map,
        position: {
          lat: member.lat,
          lng: member.lng
        },
        icon: {
          url: '/static/images/marker.png'
        }
      })

      marker.addListener('click', () => {
        const membersWithSameLatLng = members.rows!.filter(m => m.lat === member.lat && m.lng === member.lng)

        const names = membersWithSameLatLng.map(m => m.name).join(', ')

        infoWindow.setContent(names)
        infoWindow.open(map, marker)
      })

      return marker
    })

    // @ts-ignore
    new MarkerClusterer({ markers, map })
  }

  return (
    <Container>
      <Fade in={true} mountOnEnter unmountOnExit>
        <Stack>
          <Typography variant='h6'>Good to see you, {user?.name.split(' ').slice(0, -1).join(' ')}!</Typography>
          <Typography variant='subtitle1'>Welcome to Rampt.dev Dashboard.</Typography>
        </Stack>
      </Fade>
      <Grid container spacing={12}>
        <Grid item xs={12}>
          <Card sx={{ mt: 5 }}>
            <CardContent>
              <Grid container spacing={6}>
                {links.map(link => (
                  <LinkCard key={link.number} link={link} />
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Request & Submit
          </Typography>
          <Grid container spacing={6}>
            {forms.map(form => (
              <FormCard key={form.name} form={form} />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Upcoming Positions
          </Typography>
          <Grid container spacing={6}>
            {loadingPositions && <PositionLoading />}

            {!loadingPositions && positions.length == 0 && (
              <Grid item xs={12}>
                <Typography variant='subtitle2'>No postiions to display at the moment.</Typography>
              </Grid>
            )}

            {!loadingPositions && positions.length != 0 && (
              <>
                {positions.map(position => (
                  <PositionCard key={position.id} position={position} />
                ))}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Members Arround the Globe
          </Typography>

          <Box sx={{ height: '70vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyC8__l_K72KCrqtDF6AeQVxD__SamzqEzc' }}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={defaultMapProps.center}
              defaultZoom={defaultMapProps.zoom}
              onGoogleApiLoaded={({ map, maps }) => {
                handleMapLoaded(map, maps)
              }}
              options={{
                styles: defaultMapProps.styles
              }}
            ></GoogleMapReact>
          </Box>
        </Grid>
        {role == 'admin' && (
          <>
            <Grid item xs={12}>
              <AdminDivider />
              <Button color='primary' variant='outlined' onClick={() => navigate('/positions/add')}>
                New position
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export type LinkCardProps = {
  link: LinkRampt
}

const LinkCard = (props: LinkCardProps) => {
  const { link } = props

  return (
    <Grid item xs>
      <Card sx={{ backgroundColor: 'background.darkBlue', height: '100%' }}>
        <CardActionArea sx={{ height: '100%' }} component={Link} to={link.url}>
          <CardContent>
            <Avatar sx={{ mb: 3 }}>
              <Iconify width={25} icon='iconoir:stats-up-square-solid' sx={{ color: 'primary.main' }} />
            </Avatar>
            <Typography
              variant='caption'
              sx={{ fontSize: '1rem', fontWeight: 'bolder', display: 'block', color: 'secondary.dark' }}
            >
              {link.number}
            </Typography>
            <Typography variant='body1' sx={{ mt: 1 }}>
              {link.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export type FormCardProps = {
  form: LinkRampt
}

const FormCard = (props: FormCardProps) => {
  const { form } = props

  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ height: '100%', mt: 2 }}>
        <CardActionArea sx={{ height: '100%' }} component={Link} to={form.url}>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'start',
              verticalAlign: 'center',
              height: '100%',
              alignItems: 'center'
            }}
          >
            <Iconify width={45} icon={form.icon!} />
            <Typography variant='subtitle2' sx={{ ml: 8, color: 'primary.main', fontWeight: 'bolder' }}>
              {form.name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export type PositionCardProps = {
  position: Position
}

const PositionCard = (props: PositionCardProps) => {
  const { position } = props
  const navigate = useNavigate()

  return (
    <Grid item xs={12} md={3}>
      <Card sx={{ height: '100%', mt: 2 }}>
        <CardActionArea sx={{ height: '100%' }} onClick={() => navigate(`/positions/${position.id}`)}>
          <CardContent>
            <Iconify width={65} icon={position.icon!} />
            <Typography variant='body2' sx={{ mt: 8, mb: 3 }}>
              {position.name}
            </Typography>
            <Chip label={position.salary} />
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

const PositionLoadingSkelleton = () => {
  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Skeleton variant='rounded' width={65} height={65} />
        <Skeleton variant='text' sx={{ mt: 8, mb: 3 }} />
        <Skeleton variant='rounded' width={'30%'} height={20} sx={{ fontSize: '1rem' }} />
      </CardContent>
    </Card>
  )
}

const PositionLoading = () => {
  return (
    <>
      {Array(4)
        .fill(true)
        .map((_, i) => (
          <Grid key={i} item xs={12} md={3}>
            <PositionLoadingSkelleton />
          </Grid>
        ))}
    </>
  )
}

const defaultMapProps = {
  center: {
    lat: 13.954593,
    lng: -88.711152
  },
  zoom: 2,
  styles: [
    { elementType: 'geometry', stylers: [{ color: '#474959' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#666666' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#1d232b' }] },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#333333' }]
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry',
      stylers: [{ color: '#1b1c26' }]
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry',
      stylers: [{ color: '#5b5d6e' }]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#333333' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#263c3f' }]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#6b9a76' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#212a37' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#212a37' }]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9ca5b3' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#323236' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#323236' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#323236' }]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#2f3948' }]
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#1b1c26' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#515c6d' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#17263c' }]
    }
  ]
}

export default DashboardScreen
