// ** React
import React, { useState } from 'react'

// ** MUI
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Slide from '@mui/material/Slide'

// ** Hooks
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

// ** Utils
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { object, string, ref } from 'yup'
import toast from 'react-hot-toast'

// ** Rxjs
import { finalize } from 'rxjs'
import { doOnSubscribe } from '../../../utils/rxjs'

// ** Components
import BlankCentered from '../../../components/blank-centered'

// ** Types
import { CompleteNewPasswordFormData } from '../../../types/auth'

const CompleteNewPasswordScreen = () => {
  const navigate = useNavigate()
  const { completeNewPassword$ } = useAuth()

  const [loading, setLoading] = useState(false)

  const initialValues: CompleteNewPasswordFormData = {
    password: '',
    passwordConfirm: ''
  }

  const validationSchema = object().shape({
    password: string().min(6, `The password must contain at least 6 characters`).required('The password is required'),
    passwordConfirm: string()
      .oneOf([ref('password')], 'Passwords must match')
      .required('Password confirmation is mandatory')
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CompleteNewPasswordFormData>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = handleSubmit(data => {
    completeNewPassword$({
      password: data.password
    })
      .pipe(
        doOnSubscribe(() => setLoading(true)),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {
          window.location.href = '/'
        },
        error: () => {
          toast.error('System error, please try again later')
          navigate('/')
        }
      })
  })

  return (
    <BlankCentered className='layout-wrapper'>
      <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
        <Box className='content-center'>
          <Slide direction={'down'} in={true} mountOnEnter unmountOnExit>
            <Container maxWidth='xs'>
              <form onSubmit={onSubmit}>
                <Box
                  sx={{
                    mb: 12,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img alt={'Logo'} width={280} src={`/static/images/logo-dark.webp`} />
                </Box>

                <Box sx={{ my: 4 }}>
                  <Typography variant='subtitle1' align='center'>
                    Change your password.
                  </Typography>
                </Box>

                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='password'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        error={'password' in errors}
                        fullWidth
                        helperText={errors.password?.message}
                        label='New password'
                        name='password'
                        onBlur={onBlur}
                        onChange={onChange}
                        type='password'
                        value={value}
                        variant='outlined'
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='passwordConfirm'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        error={'passwordConfirm' in errors}
                        fullWidth
                        helperText={errors.passwordConfirm?.message}
                        label='Confirm new password'
                        name='passwordConfirm'
                        onBlur={onBlur}
                        onChange={onChange}
                        type='password'
                        value={value}
                        variant='outlined'
                      />
                    )}
                  />
                </FormControl>

                <Box mt={2}>
                  <LoadingButton color='primary' loading={loading} fullWidth type='submit' variant='contained'>
                    Submit
                  </LoadingButton>
                </Box>
              </form>
            </Container>
          </Slide>
        </Box>
      </Box>
    </BlankCentered>
  )
}

export default CompleteNewPasswordScreen
