// ** Hooks
import useAuth from '../hooks/useAuth'

// ** Modules
import AuthModule from './Auth'
import ApplicationModule from './Application'

const Modules = () => {
  const { user, cognitoUser, isAuthenticating } = useAuth()

  if (isAuthenticating || (cognitoUser && !user)) {
    return <></>
  }

  if (!cognitoUser || cognitoUser.challengeName) {
    return <AuthModule />
  }

  return <ApplicationModule />
}

export default Modules
