// ** React
import React, { ElementType, useState } from 'react'

// ** MUI
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import CardContent from '@mui/material/CardContent'
import Fade from '@mui/material/Fade'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Avatar, { AvatarProps } from '@mui/material/Avatar'
import MuiCard, { CardProps } from '@mui/material/Card'
import { styled } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'

// ** Hooks
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import useApi from '../../../hooks/useApi'

// ** Components
import BlankCentered from '../../../components/blank-centered'
import Iconify from '../../../components/iconify'

// ** Types
import { UserPutFormData } from '../../../types/user'

// ** Utils
import { convertBase64 } from '../../../utils/convert-base64'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import toast from 'react-hot-toast'
import { fmtAxiosMessage } from '../../../services/api/utils'
import { countries } from '../../../utils/countries'

// ** Styled Components
const ProfileSetupCard = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '30rem' }
}))

const AvatarStyled = styled(Avatar)<AvatarProps & { component?: ElementType; htmlFor?: string }>(() => ({
  margin: '0 auto',
  cursor: 'pointer',
  width: 100,
  height: 100,
  marginBottom: '1rem !important'
}))

const ProfileSetupScreen = () => {
  const navigate = useNavigate()
  const { user, setUser, signOut$ } = useAuth()
  const api = useApi()

  const [loading, setLoading] = useState(false)
  const [profilePicture, setProfilePicture] = useState('')

  const initialValues: UserPutFormData = {
    title: '',
    country: '',
    bio: '',
    picture: ''
  }

  const validationSchema = object().shape({
    title: string().required('required'),
    country: string().required('required'),
    bio: string().required('required')
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<UserPutFormData>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = async (data: UserPutFormData) => {
    setLoading(true)

    try {
      data.picture = profilePicture
      const user = await api.users.putMe(data)

      setUser(user)

      navigate('/')
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
    }

    setLoading(false)
  }

  const handleProfilePictureRead = async (event: any) => {
    const file = event.target.files[0]
    const base64 = (await convertBase64(file)) as string
    setProfilePicture(base64)
  }

  const handleResetFilePicture = () => {
    setProfilePicture('')
  }

  return (
    <BlankCentered className='layout-wrapper'>
      <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
        <Box className='content-center'>
          <Fade in={true} mountOnEnter unmountOnExit>
            <ProfileSetupCard>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography variant='h6' sx={{ mt: 4 }}>
                    {user?.name.split(' ').slice(0, -1).join(' ')}, set up your profile.
                  </Typography>
                  <Typography variant='subtitle2' sx={{ mt: 2, mb: 8 }}>
                    Please upload a picture and complete the details below.
                  </Typography>

                  <AvatarStyled
                    src={profilePicture}
                    alt={user?.name}
                    component='label'
                    htmlFor='account-settings-upload-image'
                    onClick={handleResetFilePicture}
                  >
                    <Iconify width={40} icon='raphael:photo' />
                    <input
                      hidden
                      type='file'
                      accept='image/png, image/jpeg'
                      id='account-settings-upload-image'
                      onChange={e => handleProfilePictureRead(e)}
                    />
                  </AvatarStyled>

                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <Controller
                      name='title'
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          error={'title' in errors}
                          fullWidth
                          helperText={errors.title?.message}
                          label='Job Title'
                          name='title'
                          onBlur={onBlur}
                          onChange={onChange}
                          type='text'
                          value={value}
                          variant='outlined'
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <Controller
                      name='country'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          options={countries}
                          getOptionLabel={option => option.label}
                          defaultValue={{
                            code: '',
                            phone: '',
                            label: value ?? ''
                          }}
                          onChange={(_, data) => onChange(data?.label)}
                          renderOption={(props, option) => (
                            <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              <img
                                loading='lazy'
                                width='20'
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=''
                              />
                              {option.label} ({option.code})
                            </Box>
                          )}
                          renderInput={params => (
                            <TextField
                              {...params}
                              error={'country' in errors}
                              fullWidth
                              helperText={errors.country?.message}
                              label='Country'
                              name='country'
                              type='text'
                              value={value}
                              variant='outlined'
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <Controller
                      name='bio'
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          error={'bio' in errors}
                          multiline
                          rows={4}
                          fullWidth
                          helperText={errors.bio?.message}
                          label='Bio'
                          placeholder='Write a brief biography about who you are, and the things you like to do both inside and outside of work.'
                          name='bio'
                          onBlur={onBlur}
                          onChange={onChange}
                          type='text'
                          value={value}
                          variant='outlined'
                        />
                      )}
                    />
                  </FormControl>

                  <Box mt={2} mb={4}>
                    <LoadingButton color='primary' loading={loading} fullWidth type='submit' variant='contained'>
                      Save
                    </LoadingButton>
                  </Box>
                  <Divider component='div' role='presentation'>
                    <Typography variant='overline'>It's not your account?</Typography>
                  </Divider>
                  <Typography
                    onClick={signOut$}
                    variant='body2'
                    sx={{ color: 'primary.main', cursor: 'pointer', display: 'inline-block' }}
                  >
                    Sign out
                  </Typography>
                </CardContent>
              </form>
            </ProfileSetupCard>
          </Fade>
        </Box>
      </Box>
    </BlankCentered>
  )
}

export default ProfileSetupScreen
