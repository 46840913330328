import { Client } from './client'
import { User, UserPutFormData, UserListRequest, UserRange } from '../../types/user'
import { Position, PositionFormData, PositionListRequest, PositionRange } from '../../types/position'
import { Project, ProjectFormData, ProjectListRequest, ProjectRange } from '../../types/project'
import { TimeOff, TimeOffFormData, TimeOffListRequest, TimeOffRange } from '../../types/timeOff'

export class ApiService extends Client {
  users = {
    me: (): Promise<User> => {
      return this.request().append('/users/me').build()
    },
    putMe: (data: UserPutFormData): Promise<User> => {
      return this.request().setData(data).method('put').append('/users/me').build()
    },
    list: (params?: Partial<UserListRequest>): Promise<Partial<UserRange>> => {
      return this.request().append('/users').params(params).method('get').build()
    },
    get: (id: string): Promise<User> => {
      return this.request().append('/users/').append(id).build()
    }
  }

  positions = {
    list: (params?: Partial<PositionListRequest>): Promise<Partial<PositionRange>> => {
      return this.request().append('/positions').params(params).method('get').build()
    },
    create: (data: PositionFormData): Promise<Position> => {
      return this.request().append('/positions').setData(data).method('post').build()
    },
    update: (id: string, data: PositionFormData): Promise<Position> => {
      return this.request().append('/positions/').append(id).setData(data).method('put').build()
    },
    get: (id: string): Promise<Position> => {
      return this.request().append('/positions/').append(id).build()
    },
    delete: (id: string): Promise<void> => {
      return this.request().append('/positions/').append(id).method('delete').build()
    }
  }

  projects = {
    list: (params?: Partial<ProjectListRequest>): Promise<Partial<ProjectRange>> => {
      return this.request().append('/projects').params(params).method('get').build()
    },
    create: (data: ProjectFormData): Promise<Project> => {
      return this.request().append('/projects').setData(data).method('post').build()
    },
    update: (id: string, data: ProjectFormData): Promise<Project> => {
      return this.request().append('/projects/').append(id).setData(data).method('put').build()
    },
    get: (id: string): Promise<Project> => {
      return this.request().append('/projects/').append(id).build()
    },
    delete: (id: string): Promise<void> => {
      return this.request().append('/projects/').append(id).method('delete').build()
    }
  }

  timeOffs = {
    list: (params?: Partial<TimeOffListRequest>): Promise<Partial<TimeOffRange>> => {
      return this.request().append('/time-offs').params(params).method('get').build()
    },
    create: (data: TimeOffFormData): Promise<TimeOff> => {
      return this.request().append('/time-offs').setData(data).method('post').build()
    },
    get: (id: string): Promise<TimeOff> => {
      return this.request().append('/time-offs/').append(id).build()
    },
    delete: (id: string): Promise<void> => {
      return this.request().append('/time-offs/').append(id).method('delete').build()
    },
    discover: (id: string): Promise<TimeOff> => {
      return this.request().append('/admin/time-offs/').append(id).build()
    },
    discoverList: (params?: Partial<TimeOffListRequest>): Promise<Partial<TimeOffRange>> => {
      return this.request().append('/admin/time-offs').params(params).method('get').build()
    },
    approve: (id: string): Promise<TimeOff> => {
      return this.request().append('/admin/time-offs/').append(id).method('put').build()
    },
    reject: (id: string): Promise<TimeOff> => {
      return this.request().append('/admin/time-offs/').append(id).method('delete').build()
    }
  }
}
