// ** React
import React, { forwardRef, HTMLProps } from 'react'

// ** Third Party
import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'

// ** MUI
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'

interface IconifyProps extends HTMLProps<HTMLDivElement> {
  icon: string | object
  sx?: SxProps
  width?: number
}

const Iconify = forwardRef<HTMLDivElement, IconifyProps>(({ ...props }, _) => (
  <Box
    component={Icon}
    className='component-iconify'
    sx={{ width: props.width || 20, height: props.width || 20, ...props.sx }}
    // @ts-ignore
    icon={props.icon}
  />
))

Iconify.propTypes = {
  // @ts-ignore
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  width: PropTypes.number
}

export default Iconify
