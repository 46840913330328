// ** MUI Imports
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

// ** Types
import { AdminDividerProps } from './types'

const AdminDivider = (_: AdminDividerProps) => {
  return (
    <Divider
      component='div'
      role='presentation'
      sx={{
        '&::before, &::after': {
          borderColor: 'primary.main'
        }
      }}
    >
      <Typography variant='caption' sx={{ color: 'primary.main', fontWeight: 'bolder' }}>
        Admin actions
      </Typography>
    </Divider>
  )
}

export default AdminDivider
