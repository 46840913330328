// ** React
import React from 'react'

// ** MUI
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

// ** Hooks
import { useNavigate } from 'react-router-dom'

// ** Components
import BlankCentered from '../../../components/blank-centered'

const NotFoundScreen = () => {
  const navigate = useNavigate()

  return (
    <BlankCentered className='layout-wrapper'>
      <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
        <Box className='content-center'>
          <Container maxWidth='xs' sx={{ textAlign: 'center' }}>
            <Typography align='center' variant='h3'>
              404
            </Typography>
            <Typography align='center' variant='subtitle2' gutterBottom>
              The page that you was looking for was not found.
            </Typography>
            <Button onClick={() => navigate('/')} component='a' sx={{ mt: 3 }} variant='contained'>
              Back
            </Button>
          </Container>
        </Box>
      </Box>
    </BlankCentered>
  )
}

export default NotFoundScreen
