// ** React
import React, { useEffect, forwardRef } from 'react'

// ** Utils
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// ** MUI
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
import Fade from '@mui/material/Fade'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import ListItemButton from '@mui/material/ListItemButton'
import { alpha } from '@mui/material/styles'

// ** Hooks
import useAuth from '../../hooks/useAuth'
import { useResponsive } from '../../hooks/useResponsive'
import { usePathname } from '../../hooks/usePathname'

// ** Configs
import { NAV } from './config-layout'
import navConfig from './config-navigation'

interface NavProps {
  openNav: boolean
  onCloseNav: () => void
}

interface NavItemProps {
  item: {
    path: string
    icon: React.ReactNode
    title: string
  }
}

export default function Nav({ openNav, onCloseNav }: NavProps) {
  const { user, role } = useAuth()
  const pathname = usePathname()

  const upLg = useResponsive('up', 'lg')

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
  }, [pathname])

  const renderAccount = (
    <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
      <Box
        sx={{
          my: 5,
          mx: 2.5,
          py: 3,
          px: 2.5,
          display: 'flex',
          borderRadius: 1.5,
          alignItems: 'center',
          bgcolor: theme => alpha(theme.palette.grey[500], 0.12)
        }}
      >
        <Box sx={{ ml: 2 }}>
          <Typography variant='subtitle1'>{user?.name}</Typography>

          <Typography variant='body2'>{user?.title}</Typography>
        </Box>
      </Box>
    </Fade>
  )

  const renderMenu = (
    <Slide direction={'right'} in={true} mountOnEnter unmountOnExit>
      <Stack component='nav' spacing={3} sx={{ px: 2 }}>
        {navConfig.map(item => (
          <NavItem key={item.title} item={item} />
        ))}
      </Stack>
    </Slide>
  )

  const renderContent = (
    <Box
      sx={{
        height: 1,
        px: 2,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          ml: 4,
          my: 6.5,
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'start'
        }}
      >
        <img alt={'Logo'} width={160} src={`/static/images/logo-dark.webp`} />
      </Box>

      {renderAccount}

      {renderMenu}
    </Box>
  )

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH }
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.WIDTH,
            borderRight: theme => `dashed 1px ${theme.palette.divider}`
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func
}

function NavItem({ item }: NavItemProps) {
  const pathname = usePathname()

  const isNavLinkActive = () => {
    const itemPath = item.path || '/'
    if (pathname != '/' && itemPath != '/' && pathname.includes(itemPath)) {
      return true
    }

    return pathname === item.path
  }

  return (
    <ListItemButton
      component={RouterLink}
      href={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: 'body2',
        color: 'text.secondary',
        textTransform: 'capitalize',
        fontWeight: 'fontWeightMedium',
        ...(isNavLinkActive() && {
          color: 'primary.main',
          fontWeight: 'fontWeightSemiBold',
          bgcolor: theme => alpha(theme.palette.primary.main, 0.08),
          '&:hover': {
            bgcolor: theme => alpha(theme.palette.primary.main, 0.16)
          }
        })
      }}
    >
      <Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>

      <Box component='span'>{item.title} </Box>
    </ListItemButton>
  )
}

NavItem.propTypes = {
  item: PropTypes.object
}

interface RouterLinkProps {
  href: string
}

const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(({ href, ...other }, ref) => (
  <Link ref={ref} to={href} {...other} />
))
