import * as qs from 'qs'

export interface RequestBuilderConfig {
  url: string
  data?: any
  headers: any
  method?: string
  params: URLSearchParams
}

export class RequestBuilder {
  config: RequestBuilderConfig
  client: any
  values: any

  constructor({ client, headers }: { client: any; headers: any }) {
    this.config = {
      url: process.env.REACT_APP_API_URL!,
      params: new URLSearchParams(),
      headers: {
        'content-type': 'application/json',
        ...headers
      }
    }

    this.client = client

    return this
  }

  contentType(type: string) {
    this.config.headers['content-type'] = type

    return this
  }

  append(url: string) {
    this.config.url = (this.config.url || '') + url

    return this
  }

  method(name: string) {
    this.config.method = name

    return this
  }

  params(params: any) {
    for (const key in params) {
      if (!params[key]) {
        continue
      }

      this.config.params.append(key, params[key])
    }

    return this
  }

  setData(data: any) {
    this.config.data = data

    if (this.config.headers['content-type'] === 'application/x-www-form-urlencoded') {
      this.config.data = qs.stringify(data)
    }

    if (this.config.headers['content-type'] === 'multipart/form-data') {
      const formData = new FormData()
      for (const key in data) {
        formData.append(key, data[key])
      }

      this.config.data = formData
    }

    return this
  }

  build() {
    return this.client.build(this.config).then((response: any) => response.data)
  }
}
