// ** React
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// ** Contexts
import AuthProvider from './context/AuthContext'

// ** Modules
import Modules from './modules'
import ThemeComponent from './theme/ThemeComponent'

// ** Third party
import NProgress from 'nprogress'
import { Toaster } from 'react-hot-toast'
import { configureAmplify } from './lib/amplify'

// ** Library styles
import 'nprogress/nprogress.css'
import 'moment-duration-format'
import 'react-datepicker/dist/react-datepicker.css'
import ReactHotToast from './styles/react-hot-toast'

// ** Global css styles
import './styles/globals.css'

NProgress.configure({ showSpinner: false })

configureAmplify()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeComponent>
    <BrowserRouter>
      <AuthProvider>
        <Modules />
      </AuthProvider>
    </BrowserRouter>
    <ReactHotToast>
      <Toaster position={'top-right'} toastOptions={{ className: 'react-hot-toast', duration: 5000 }} />
    </ReactHotToast>
  </ThemeComponent>
)
