import { RequestBuilder, RequestBuilderConfig } from './request'
import axios from 'axios'
import { AxiosError } from 'axios'
const client = axios.create()
import { Auth } from '@aws-amplify/auth'

export class ClientRequestValues {
  values: Record<string, string>

  constructor(initial = {}) {
    this.values = initial
  }

  setValue(key: any, value: any) {
    this.values[key] = value
  }

  getValue(key: string) {
    return this.values[key]
  }

  extend(ctx: ClientRequestValues) {
    for (const key in ctx.values) {
      this.setValue(key, ctx.values[key])
    }
  }
}

export class Client {
  baseURL: string
  headers: Record<string, string>
  values: ClientRequestValues

  constructor(settings = {}) {
    // @ts-ignore
    const { baseURL, headers = {}, values } = settings
    this.values = new ClientRequestValues()

    if (values) {
      this.values.extend(values)
    }

    this.baseURL = baseURL
    this.headers = headers
  }

  async build(config: RequestBuilderConfig) {
    const reg = /{([a-zA-Z]+)}/g

    let result

    while ((result = reg.exec(config.url)) !== null) {
      const [fullMatch, name] = result
      config.url = config.url.replace(fullMatch, this.values.getValue(name))
    }

    try {
      const session = await Auth.currentSession()
      const accessToken = session.getIdToken().getJwtToken()

      config.headers = {
        ...config.headers,
        Authorization: accessToken
      }

      return client(config)
    } catch (err: any) {
      return Promise.reject(err)
    }
  }

  request(initialValues = {}) {
    const headers: Record<string, string> = {}
    for (const key in this.headers) {
      const resolve = this.headers[key]

      if (typeof resolve === 'function') {
        continue
      }

      headers[key] = resolve
    }

    const req = new RequestBuilder({
      client: this,
      headers: headers
    })

    req.values = new ClientRequestValues(initialValues)

    return req
  }
}

export function fmtAxiosMessage(err: AxiosError | any): string {
  if (err.response?.data?.message) {
    return err.response?.data?.message
  }

  return err.code || ''
}
