// ** React
import React, { useState } from 'react'

// ** MUI
import { LoadingButton } from '@mui/lab'
import { Google } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import FormControl from '@mui/material/FormControl'
import Divider from '@mui/material/Divider'

// ** Hooks
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

// ** Utils
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import toast from 'react-hot-toast'

// ** Rxjs
import { finalize } from 'rxjs'
import { doOnSubscribe } from '../../../utils/rxjs'

// ** Components
import BlankCentered from '../../../components/blank-centered'

// ** Types
import { SignInFormData } from '../../../types/auth'

const SignInScreen = () => {
  const navigate = useNavigate()
  const { signIn$, signInGoogle$ } = useAuth()

  const [loading, setLoading] = useState(false)

  const initialValues: SignInFormData = {
    email: '',
    password: ''
  }

  const validationSchema = object().shape({
    email: string().email('Must be a valid email address').required(`The email address is required`),
    password: string().min(6, `The password must contain at least 6 characters`).required('The password is required')
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<SignInFormData>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = handleSubmit(data => {
    signIn$({
      email: data.email,
      password: data.password
    })
      .pipe(
        doOnSubscribe(() => setLoading(true)),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: result => {
          console.log(result)
          if (result.forcePasswordChange) {
            navigate('/complete-new-password')

            return
          }

          navigate('/')
        },
        error: err => {
          console.log(err)
          toast.error('The password entered is incorrect. Please try again')
        }
      })
  })

  const handleSignInGoogle = () => {
    signInGoogle$()
      .pipe(
        doOnSubscribe(() => setLoading(true)),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {},
        error: err => {
          toast.error(err)
        }
      })
  }

  return (
    <BlankCentered className='layout-wrapper'>
      <Box className='app-content' sx={{ minHeight: '100vh', overflowX: 'hidden', position: 'relative' }}>
        <Box className='content-center'>
          <Fade in={true} mountOnEnter unmountOnExit>
            <Container maxWidth='xs'>
              <form onSubmit={onSubmit}>
                <Box
                  sx={{
                    mb: 12,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img alt={'Logo'} width={280} src={`/static/images/logo-dark.webp`} />
                </Box>

                <Box sx={{ my: 4, pb: 2 }}>
                  <Typography variant='subtitle2' align='center'>
                    Please, authenticate to continue.
                  </Typography>
                </Box>

                <Box>
                  <Button
                    color='inherit'
                    fullWidth
                    type='button'
                    variant='contained'
                    sx={{ backgroundColor: '#fff', color: '#333', textTransform: 'none' }}
                    startIcon={<Google />}
                    onClick={() => handleSignInGoogle()}
                  >
                    Sign in with Google
                  </Button>
                </Box>

                <Divider component='div' role='presentation' sx={{ my: 3 }}>
                  <Typography variant='overline'>or</Typography>
                </Divider>

                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='email'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        error={'email' in errors}
                        fullWidth
                        helperText={errors.email?.message}
                        label='E-mail'
                        name='email'
                        onBlur={onBlur}
                        onChange={onChange}
                        type='email'
                        value={value}
                        variant='outlined'
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name='password'
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextField
                        error={'password' in errors}
                        fullWidth
                        helperText={errors.password?.message}
                        label='Password'
                        name='password'
                        onBlur={onBlur}
                        onChange={onChange}
                        type='password'
                        value={value}
                        variant='outlined'
                      />
                    )}
                  />
                </FormControl>

                <Box mt={2}>
                  <LoadingButton color='primary' loading={loading} fullWidth type='submit' variant='contained'>
                    Login
                  </LoadingButton>
                </Box>
              </form>
            </Container>
          </Fade>
        </Box>
      </Box>
    </BlankCentered>
  )
}

export default SignInScreen
