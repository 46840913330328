// ** React
import React, { useState, MouseEvent } from 'react'

// ** Utils
import PropTypes from 'prop-types'

// ** MUI
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'

// ** Components
import Iconify from '../../../components/iconify'

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState<any[]>([])

  const totalUnRead = notifications.filter(item => item.isUnRead === true).length

  const [open, setOpen] = useState<null | HTMLElement>(null)

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map(notification => ({
        ...notification,
        isUnRead: false
      }))
    )
  }

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen}>
        <Badge badgeContent={totalUnRead} color='error'>
          <Iconify width={22} icon='ph:bell-bold' />
        </Badge>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              mt: 1.5,
              ml: 0.75,
              width: 360
            }
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant='subtitle2'>Notifications</Typography>
            <Typography variant='caption' sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=' Mark all as read'>
              <IconButton color='primary' onClick={handleMarkAllAsRead}>
                <Iconify icon='eva:done-all-fill' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Popover>
    </>
  )
}

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any
  })
}

type NotificationItemProps = {
  notification: {
    createdAt: Date
    id: string
    isUnRead: boolean
    title: string
    description: string
    type: string
    avatar: any
  }
}

function NotificationItem({ notification }: NotificationItemProps) {
  const { avatar, title } = renderContent(notification)

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected'
        })
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant='caption'
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled'
            }}
          >
            <Iconify icon='eva:clock-outline' sx={{ mr: 0.5, width: 16, height: 16 }} />
            {/*{fToNow(notification.createdAt)}*/}
          </Typography>
        }
      />
    </ListItemButton>
  )
}

type RenderContentProps = {
  type: string
  title: string
  description: string
  avatar: any
}

function renderContent(notification: RenderContentProps) {
  const title = (
    <Typography variant='subtitle2'>
      {notification.title}
      <Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  )

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src='/assets/icons/ic_notification_package.svg' />,
      title
    }
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src='/assets/icons/ic_notification_shipping.svg' />,
      title
    }
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src='/assets/icons/ic_notification_mail.svg' />,
      title
    }
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src='/assets/icons/ic_notification_chat.svg' />,
      title
    }
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title
  }
}
