// ** React
import React, { useEffect, useState } from 'react'

// ** AWS
import { Auth } from 'aws-amplify'
import { CognitoUser, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

// ** Rxjs
import { catchError, finalize, map, Observable } from 'rxjs'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import { doOnSubscribe } from '../utils/rxjs'

// ** Services
import { ApiService } from '../services/api/ApiService'
import { fmtAxiosMessage } from '../services/api/client'

// ** Utils
import toast from 'react-hot-toast'
import NProgress from 'nprogress'

// ** Types
import { User } from '../types/user'

export interface IAuthContextType {
  user: User | null
  setUser: (user: User | null) => void
  cognitoUser: CognitoUser | null
  role: string
  isAuthenticating: boolean
  signIn$: (input: { email: string; password: string }) => Observable<{ forcePasswordChange?: boolean }>
  signInGoogle$: () => Observable<void>
  signOut$: () => Observable<void>
  completeNewPassword$: (input: { password: string }) => Observable<void>
}

// Create a context object
export const AuthContext = React.createContext<IAuthContextType>({
  user: null,
  cognitoUser: null
} as IAuthContextType)

interface IAuthProviderProps {
  children: React.ReactNode
}

// Create a provider for components to consume and subscribe to changes
export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null)
  const [role, setRole] = useState('')
  const [isAuthenticating, setIsAuthenticating] = useState(true)

  const api = new ApiService()

  useEffect(() => {
    fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        doOnSubscribe(() => {
          setIsAuthenticating(true)
          NProgress.start()
        }),
        map(fetchedUser => {
          const cognitoUser: CognitoUser = fetchedUser as CognitoUser

          if (!cognitoUser) {
            throw Error('Current auth user cast error')
          }

          return cognitoUser
        }),
        finalize(() => {
          setIsAuthenticating(false)
          NProgress.done()
        })
      )
      .subscribe({
        next: cognitoUser => {
          // set cognito user information in state
          setCognitoUser(cognitoUser)
          setRole(cognitoUser.getSignInUserSession()?.getIdToken()?.payload?.profile ?? '')

          if (cognitoUser) {
            fetchMe()
          }
        },
        error: error => {
          console.error('currentAuthenticatedUse error', error)
        }
      })
  }, [])

  const fetchMe = async () => {
    try {
      const userResp = await api.users.me()
      setUser(userResp)
    } catch (err: any) {
      toast.error(fmtAxiosMessage(err))
      console.error(err)
    }
  }

  const signIn$ = ({
    email,
    password
  }: {
    email: string
    password: string
  }): Observable<{ forcePasswordChange?: boolean }> => {
    return fromPromise(Auth.signIn({ username: email, password })).pipe(
      map(signInResult => {
        if (!(signInResult instanceof CognitoUser)) {
          // this case should not arise as sign in result should always return CognitoUser
          throw Error('Critical Error')
        }

        // set cognito user information in state
        setCognitoUser(signInResult)
        setRole(signInResult.getSignInUserSession()?.getIdToken()?.payload?.profile ?? '')

        // set force password change
        if (signInResult.challengeName == 'NEW_PASSWORD_REQUIRED') {
          return {
            forcePasswordChange: true
          }
        }

        return {}
      }),
      catchError(err => {
        throw err
      })
    )
  }

  const signInGoogle$ = (): Observable<void> => {
    return fromPromise(
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google
      })
    ).pipe(
      map(() => {}),
      catchError(err => {
        throw err
      })
    )
  }

  const completeNewPassword$ = ({ password }: { password: string }): Observable<void> => {
    return fromPromise(Auth.completeNewPassword(cognitoUser, password)).pipe(
      map(() => {}),
      catchError(err => {
        throw err
      })
    )
  }

  const signOut$ = () => {
    return fromPromise(Auth.signOut()).pipe(map(() => setCognitoUser(null)))
  }

  const value = {
    user,
    cognitoUser,
    role,
    setUser,
    isAuthenticating,
    signIn$,
    signInGoogle$,
    signOut$,
    completeNewPassword$
  } satisfies IAuthContextType

  if (isAuthenticating) {
    return <></>
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
