// ** React
import React from 'react'

// ** Utils
import PropTypes from 'prop-types'

// ** MUI
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import { alpha } from '@mui/material/styles'

// ** Hooks
import { useResponsive } from '../../hooks/useResponsive'

// ** Components
import Iconify from '../../components/iconify'

import AccountPopover from './common/account-popover'
import NotificationsPopover from './common/notifications-popover'

// ** Configs
import { NAV, HEADER } from './config-layout'

interface HeaderProps {
  onOpenNav: () => void
}

export default function Header({ onOpenNav }: HeaderProps) {
  const theme = useTheme()

  const lgUp = useResponsive('up', 'lg')

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <Iconify icon='eva:menu-2-fill' />
        </IconButton>
      )}

      <Box sx={{ flexGrow: 1 }} />

      <Stack direction='row' alignItems='center' spacing={1}>
        <NotificationsPopover />
        <AccountPopover />
      </Stack>
    </>
  )

  return (
    <Slide direction={'down'} in={true} mountOnEnter unmountOnExit>
      <AppBar
        // @ts-ignore
        sx={{
          boxShadow: 'none',
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,
          ...headerBgBlur({
            color: theme.palette.background.default
          }),
          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter
          }),
          ...(lgUp && {
            width: `calc(100% - ${NAV.WIDTH + 1}px)`,
            height: HEADER.H_DESKTOP
          })
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 }
          }}
        >
          {renderContent}
        </Toolbar>
      </AppBar>
    </Slide>
  )
}

function headerBgBlur(props: any) {
  const color = props?.color || '#000000'
  const blur = props?.blur || 6
  const opacity = props?.opacity || 0.8

  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity)
  }
}

Header.propTypes = {
  onOpenNav: PropTypes.func
}
