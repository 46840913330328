// ** React
import React, { useState, ReactNode } from 'react'

// ** Utils
import PropTypes from 'prop-types'

// ** MUI
import Box from '@mui/material/Box'

// ** Components
import Nav from './nav'
import Main from './main'
import Header from './header'

interface RamptdevLayoutProps {
  children: ReactNode
}

export default function RamptdevLayout({ children }: RamptdevLayoutProps) {
  const [openNav, setOpenNav] = useState(false)

  return (
    <>
      <Header onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' }
        }}
      >
        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Main>{children}</Main>
      </Box>
    </>
  )
}

RamptdevLayout.propTypes = {
  children: PropTypes.node
}
